.no-books-found {
    font-size: 20px;
    text-align: center;
    padding: 20px;
}

.load-more-books {
    text-align: center;
    margin-bottom: 40px;
}
.nameList_div{
    width: 170px;
    margin-left: 30%;
}

.bookList_container{
    display: flex;
}
.publisher_container{
    flex: 1;
}
.polls-container{
    flex: 4;
    width: 100%;
    margin-left : 20px;
    margin-top: 2%;
}
.total{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.selected{
    color: #40a9ff;
    border-color: #40a9ff;
}
.data_and_search > input{
    margin-left: 20px;
    margin-right: 20px;
}
.data_and_search{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1300px) {
    .nameList_div{
        width: 170px;
        margin-left: 0;
    }
}
.loadingView{
    width: 100%;
    margin-top: 200px;
    display: flex;
    justify-content: center;
}
