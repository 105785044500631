body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.container {
  /* max-width: 1500px; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.7em;
  }
}